.common-modal-class{
  color: black;
  .reset-btn{
    padding: 2px 8px;
    margin-bottom: 0px;
  }
}

.edit,
.edit-save {
  color: black;
  display: block;
  position: fixed;
  top: 10%;
  right: 1%;
  transform: translate(0, -50%);
  z-index: 9999;
  background-color: rgb(0, 183, 255);
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;

  &:hover {
    background-color: rgb(0, 153, 255);
  }

  .pen-icon-text {
    display: none;
  }

  &:hover {
    .pen-icon-text {
      display: flex;
    }
  }
}

.edit-save {
  top: 20%;
}

.edit-active {
  [data-dynamic_placeholder] {
    position: relative;
    border: 2px solid red !important;
    // border-radius: 5px;
    cursor: pointer;

    &:hover {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 0, 0, 0.199);
        /* Red overlay with 50% opacity */
        z-index: 1;
        /* Ensure the overlay is above the content */
      }
    }
  }

  img[data-dynamic_placeholder] {
    &:hover{
      border: 2px solid rgb(4, 131, 0) !important;
    }
  }
}
.toggle-popup{
    .error-message{
        color: red;
    }
    .checkbox-container {
        display: flex;
        flex-wrap: wrap;
      }
      .checkbox-item {
        margin-right: 10px;
      }

      .checkbox-item label {
        margin-left: 5px;
      }
      .add-section {
        padding: 8px 16px;
        width: auto;
        background-color: #ff005d;
        color: #ffffff;
        border: none;
        border-radius: 10px;
        cursor: pointer;
      }
}

.toggle-popup{
  .template-card{
    font-weight: 600;
    background-color: #a2e3ff;
    border: 3px solid #a2e3ff;
    border-radius: 8px;
    cursor: pointer;
    &:hover{
      background-color: #74d3fc;
      border: 3px solid #59cdff;
    }
    &.active{
      background-color: #59cdff;
      border: 3px solid #00b3ff;
    }
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.csvuploadbtn {
  background-color: #008CBA;
  border: solid 1px rgb(161, 161, 161);
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 100%;
  // height:59px;
  border-radius: 4px;
}

@media (max-width: 1024px) {
  .csvuploadbtn {
    background-color: #008CBA;
    border: solid 1px rgb(161, 161, 161);
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .csvuploadbtn {
    background-color: #008CBA;
    border: solid 1px rgb(161, 161, 161);
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
  }
}
.modal-width{
  max-width: 80%;
  width: 60%;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.login {
  body {
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
    color: #444242;
    font-size: 14px;
  }

  .logopart {
    background-color: #f1eaed !important;
    border-bottom: 3px solid #fdbc4d;
    min-height: 65px;
    margin-bottom: 105px;
  }

  .logo {
    text-align: center;
    padding-top: 10px;
  }

  .logo,
  img {
    margin: 0px auto;
    width: 40%;
  }

  .loginesec {
    box-shadow: 0px 0px 10px 0px #cbcbcb;
    width: 35%;
    border: 1px solid #d1d1d1;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 15px auto 0px;
    padding: 35px;
  }

  .username {
    border: 1px solid #aaa;
    font-size: 15px;
    min-height: 47px;
    padding: 5px 10px;
    width: 100%;
    padding-left: 36px;
    border-radius: 6px;
    outline: none !important;
    margin-bottom: 23px;
  }

  #search_bar1 {
    position: relative;
  }

  .user_image {
    height: 24px;
    font-size: 20px !important;
    position: absolute;
    left: 22px;
    top: 13px;
  }

  .form-check-input:checked {
    width: 18px;
    height: 18px;
  }

  .form-check-label {
    font-size: 15px;
    margin: 1px 5px;
  }

  .form-check,
  a {
    text-decoration: none;
    font-size: 15px;
  }

  .btn_st {
    text-align: center !important;
  }

  .newbtn {
    width: 90%;
    background-color: #004f92;
    border: solid 2px #fff;
  }
}
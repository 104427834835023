.lead-summary {
  .checkbox-item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .option-check-input{
    width: 18px;
    height: 18px;
  }
  .option-check-input:checked{
    width: 18px;
    height: 18px;
  }
  .date-range-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;;
  }
  .date-picker{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .tablesec {
    box-shadow: 0px 0px 10px 0px #cbcbcb;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    margin-top: 15px;
  }
  .sc-jgrKcA {
    display: none;
  }
  .column-header {
    color: black;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: bold; 
    font-size: 13px;
  }
  .input-group-addon{
    // background-color: #008CBA;
    color: #030303;
    // border: 1px solid #008CBA;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: bold;
  }
}
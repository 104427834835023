.headernew {

    body {
        margin: 0;
        padding: 0;
        font-family: 'Quicksand', sans-serif;
        color: #444242;
        font-size: 14px;
    }

    .top_nav_bar {

        align-items: center;
        padding: 10px 0;
    }

    .nav-link.active {
        background-color: #ff005d;
        color: #ffffff !important;
    }

    .active,
    .nav-link:hover {
        background-color: #ff005d;
        color: #ffffff;
    }

    .nav-link {
        border: solid 0px;
        color: #000000 !important;
        text-decoration: none;
        font-size: 16px;
        margin: 0 5px 0 0;
        padding: 10px 15px !important;
        border-radius: 14px;
    }


    @media only screen and (max-width: 991px) {
        .click_links {
            border: 0 !important;
            text-align: end !important;
        }

        .user_content {
            text-align: end !important;
            padding-top: 3px;
            font-size: 15px;
            color: black;
        }
    }

    .input-grp-adn-date {
        padding: 0.5rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;
        background-color: #008cba !important;
        margin-right: 0px !important;
    }

    #daterange_alert,
    #trans_alert_success,
    #trans_alert_fail,
    #daterangealert {
        display: none;
    }

    html body .m-t-40 {
        margin-top: 30px;
    }

    html body .m-r-10 {
        margin-right: 10px;
    }

    .tablesec-bg {
        height: auto !important;
    }

    .table_hold-1 {
        padding: 15px;
        margin: 2px auto 15px;
    }

    table {
        border: 0px !important;
        border-radius: 0px !important;
    }

    #search_alert {
        font-size: 14px;
    }

    .button_a {
        padding: 6px 30px !important;
        /* font-size: 15px !important; */
    }

    table.table-condensed tr th {
        padding: 8px 1px !important;
        background-color: #fff !important;

    }

    .search-1 {
        border-radius: 0px !important;
    }

    .table-condensed {
        font-size: 14px;
    }

    .table-condensed td {
        padding: 8px;
    }

    .resp-link {
        font-size: 13px;
        font-weight: bold;
        color: #010704;
        text-decoration: none;

    }

    .resp-link:hover {
        text-decoration: none;
        color: #010704;
    }

    .btn-unauthorize {
        opacity: 0.6;
        cursor: not-allowed !important;
    }

    .search-1 {
        padding: 30px !important;
    }

    .dropdown-menu[data-bs-popper] {
        top: 64%;
    }

    #TransListTable_wrapper .dataTables_processing {
        display: none !important;
    }

    #consentUsersTable_wrapper {
        padding: 15px;
    }

    /* .mob-nav-btn {
margin-left: 19px;
margin-right: 14px;
} */
    /* #demo_info {
overflow-x: scroll;
} */

    .form-control:focus,
    .page-link:focus {
        box-shadow: none !important;
    }

    .loader-bg {
        position: fixed !important;
    }

    #loader {
        display: none;
    }


    @media screen and (min-width: 345px) and (max-width: 510px) {
        .loginesec {
            width: 90% !important;
        }

        .btn-reset {
            align-items: center !important;
            margin-top: 20px !important;
            justify-content: space-evenly;
            gap: 5px;
        }

        .btn-reset .form-group {
            width: 50%;
        }

        .btn-reset .form-group #listSrch {
            width: 100%;
        }

        .btn-reset .form-group #btnReset {
            width: 100%;
        }

        .button_a.button2.submit {
            width: 100% !important;
        }

        .logo {
            width: 100px !important;
        }

        .input-group {
            display: block !important;
        }

        #list-date-range {
            display: flex !important;
        }

        #startDate {
            width: 100% !important;
            margin-bottom: 21px !important;
        }

        .input-grp-adn-date {
            display: none;
            position: relative !important;
            top: -37px !important;
            left: 90% !important;
        }

        #endDate {
            width: 100% !important;
        }


        .pagination .previous a.page-link {
            margin-left: 80px !important;
        }

        .date-range input#from_date {
            width: 100% !important;
        }

        .date-range input#to_date {
            width: 100% !important;
        }

        .button_a {
            padding: 8px 30px !important;
        }

        .pg-ttl {
            margin: 0 auto 10px !important;
        }

        #demo_info_trans {
            overflow-x: scroll;
        }
    }

    @media screen and (min-width: 510px) and (max-width: 990px) {
        .logo_img {
            width: 100% !important;
        }

        #demo_info_trans {
            overflow-x: scroll;
        }
    }

    .logo_img {
        width: 140% ;
        margin: 10px;
    }

    .padding-adjust {
        padding-top: 5px;
    }

    @media (min-width: 992px) {
        .col-lg-5 {
            flex: 0 0 auto;
            width: 41.66666667%;
        }
    }

    .nav-item {
        margin-left: 5px;
        border-radius: 16px;
        cursor: pointer;
    }

    .dropdown,
    .dropstart,
    .dropup-center,
    .dropdown-center {
        position: relative;
    }

    .show {
        display: block;
    }

    .navbar {
        padding: 0 0 0 35%;
        float: right;
    }

    .user_content {
        padding-top: 3px;
        font-size: 15px;
        color: black;
    }


}
.module{
    .table_hold{
      .keyword-search{
          width: 40%;
          padding: 8px 12px;
          margin-bottom: 15px;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
          font-size: 14px;
      }
    }
  }
@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
	margin: 0;
	padding: 0;
  font-family: 'Quicksand', sans-serif;
	color:#444242;
	font-size: 14px;
}
header {
  box-shadow: 0px 1px 18px 0px #cbcbcb;
  margin: 0px;
  padding: 7px;
  background-color: #ffffff !important;
  color: #fff;
}
.logo_img{
  width: 65%;
}
.nav-link {
  color: #ffffff;
  text-decoration: none;
}
.navbar {
  padding: 0px 0px 0px 65%;
}
.nav-link {
  line-height: 14px;
  font-size: 16px;
}
.offcanvas-sec{
 margin-top: 25px; 
}
.nav-item :hover{
  color: #444;
  background-color: #fff;
}
.offcanvas-btn{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 17px;
}
.offcanvas-start{
  width: 70% !important;
  border-right: 1px solid rgb(9, 134, 236) !important;
}
.canvas_spc{
  margin-bottom: 35px;
}
.offcanvas-body, label{
  margin-right: 12px;
  margin-bottom: 1px;
}
.sect{
  margin-bottom: 10px;
}
.clr{
  margin: 0px;
  padding: 0px;
  clear: both;
}
.button_a {
  background-color: #008CBA;
  border: solid 1px rgb(161, 161, 161);
  color: white;
  padding: 6px 30px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
}
.button_a:hover {
  background-color: #358df3;
}
.zoom {
  transition: transform .2s;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(2.5); /* IE 9 */
  -webkit-transform: scale(2.5); /* Safari 3-8 */
  transform: scale(2.5); 
}

.tablesec-bg{
  box-shadow: 0px 0px 10px 0px #cbcbcb;
  border: 1px solid #d1d1d1;
  background-color:#f5f5f5; 
  border-radius: 5px;
  margin-top: 15px;
}
.footbtn{
  text-align: right !important;
}
#search_bar {
  width: 85%;
  margin: 0 auto;
  position: relative;
}

.search_icon_image {
  height: 24px;
  position: absolute;
  right: 24px;
  top: 8px;
}

#search {
  border: 1px solid #aaa;
  font-size: 12px;
  padding: 5px 10px;
  width: 100%;
  padding-left: 28px;
  border-radius: 20px;
  outline: none !important;
}

#search_bar1 {
  position: relative;
}

#search1 {
  border: 1px solid #aaa;
  font-size: 12px;
  padding: 5px 10px;
  width: 100%;
  padding-left: 28px;
  border-radius: 20px;
  outline: none !important;
}

.dropbtn .fa-angle-down{
  font-size: 20px;
  float: right;
}

.dropbtn {
  border: 1px solid #aaa;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 4px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 195px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid #d1d1d1;
  margin-top: 3px;
}

.dropdown-content a {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  font-size: 12px;
  font-weight: 700;
}

.dropdown a:hover {background-color: #ddd;}
.dropdown1 a:hover {background-color: #ddd;}

.show {display: block;}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.fa-sort-desc{
  margin-left: 22px;
}

.fa-download, .fa-refresh, .fa-filter{
  color: #075bf9;
  font-size: 14px !important;
  font-weight: normal;
  cursor: pointer;
}

.fa-angle-down{
  color: rgb(17, 219, 17);
  font-size: 18px !important;
}

.dowmaarow{
  margin-left: 30px;
}

.update-txt{
  font-size: 12px;
}

.table-section{
  padding:25px;
}
.btn-sectio{
  padding: 0px 25px 25px;
  margin-bottom: 7%;
}
.btn-primay{
  background-color: #f54e00;
  color: #fff;
}
.btn-primay:hover{
  color: #fff;
  background-color: #bb3c02;
}
.uiui{
  border-radius:15px;
  border: solid 1px #2e72a7;
}
.table_hold{
  margin: 2px auto 15px;
  border: solid 1px #cfc9c9;
  margin: 2px auto 15px;
  padding: 0px;
  width: 98%;
  -moz-border-radius:10px;
  -webkit-border-radius:10px;
  border-radius:10px
}
table {
  border-collapse: collapse;
  width: 100%;
  margin: 0px auto;
  border: 1px solid #ccc;
  overflow: hidden;
  -moz-border-radius:10px;
  -webkit-border-radius:10px;
  border-radius:10px
}

td{
  text-align: left;
  padding: 12px;
  border: 1px solid #ccc;
}
th {
  text-align: center;
  padding: 8px;
  border: 1px solid #ccc;
}

tr th{
  background-color: #e9e9e9;
  padding: 15px 10px;
  border: 1px solid #ccc;
  color: #000;
}

.green{
  color: rgb(9, 143, 9);
  font-weight: 700;
}

.orange{
  color: rgb(226, 139, 9);
  font-weight: 700;
}

.red{
  color: rgb(196, 13, 13);
  font-weight: 700;
}

.tab{
  background-color: #466783;
  border-radius: 8px;
}

.tablinks{
  border: none;
  background-color: inherit;
  color: #fff;
  cursor: pointer;
  padding: 0px 15px;
  font-weight: 600;
}
.tablinks.active{
  background-color: #000;
  padding: 10px 18px;
  border-radius: 8px;
}
 .tablinks:focus{
  outline: none;
 }

.accordionsec{
  border-radius: 12px;
}

.accordion {
  background-color: #deecf7;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.panel{
  padding: 12px 18px !important;
}

.accordion:hover {
  background-color: #ccc; 
}

.accordion:focus {
  border: 1px solid #2e72a7 !important;
  outline: none;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #444;
  color: #fff;
  padding: 15px;
}
.style_p{
  margin: 0px;
  padding: 0px;
}


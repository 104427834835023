.AddRule {
  .dynamic-section {
    border-top: 1px solid #a5a5a5;
    margin-top: 20px;
    padding-top: 20px;

    .text-button {
      cursor: pointer;
    }
  }

  .add-button {
    margin-left: auto;
    margin-right: 0;
  }


  .add-section {
    padding: 8px 16px;
    width: auto;
    background-color: #ff005d;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  button {
    background-color: #ff005d;
  }

  .add-section:hover,
  button:hover {
    background-color: #ce004b;
  }

  select:hover {
    cursor: pointer;
  }

  input:focus,
  select:focus {
    border-color: #ff005d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
  }

  input:checked {
    background-color: #ff005d;
    border: #ce004b;

  }

  .checked {
    border-color: #ff005d;
  }

  .rule-dy-btn {
    border: none;
    /* background-color: #ff005d; */
    width: auto;
    border-radius: 10px;
    height: 21px;
    padding: 0px 7px;
    color: white;
    float: inline-end;
    margin-right: 2px
  }

}

.splitFlow {
  .dynamic-section {
    border-top: 1px solid #a5a5a5;
    margin-top: 20px;
    padding-top: 20px;

    .text-button {
      cursor: pointer;
    }
  }

  .add-button {
    margin-left: auto;
    margin-right: 0;
  }


  .add-section {
    padding: 0px 16px;
    width: auto;
    background-color: #ff005d;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  button {
    background-color: #ff005d;
  }

  .add-section:hover,
  button:hover {
    background-color: #ce004b;
  }

  select:hover {
    cursor: pointer;
  }

  input:focus,
  select:focus {
    border-color: #ff005d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
  }

  input:checked {
    background-color: #ff005d;
    border: #ce004b;

  }

  .checked {
    border-color: #ff005d;
  }

  .rule-dy-btn {
    border: none;
    /* background-color: #ff005d; */
    width: auto;
    border-radius: 10px;
    height: 21px;
    padding: 0px 7px;
    color: white;
    float: inline-end;
    margin-right: 2px
  }

  button.btn-flw-evt {
    background: none !important;
    border: none;
    padding: 4px 2px;
    font-size: 16px;
  }

  button.btn-flw-evt-cls {
    border: none;
    color: white;
    border-radius: 17px;
    padding-top: -7px;
    height: 13px;
    width: 3px;
  }
}
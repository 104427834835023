.loaders {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(95, 94, 94, 0.438);
  /* Adjust the background color and opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  .loader {
    border: 6px solid #f3f3f3;
    /* Light grey */
    border-top: 6px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
.usermanagement {
    // .table_hold {
    //     .keyword-search {
    //         width: 40%;
    //         padding: 8px 12px;
    //         margin-bottom: 15px;
    //         border: 1px solid #ccc;
    //         border-radius: 4px;
    //         box-sizing: border-box;
    //         font-size: 14px;
    //     }
    // }
    .table_hold {
        margin: 2px auto 15px !important;
        border: solid 1px #cfc9c9;
        margin: 4px auto 15px;
        padding: 1px;
        width: 98%;
        border-radius: 10px;
    }

    .pg-ttl {
        margin: 8px !important;
        font-size: large !important;
        font-weight: 700 !important;
    }

    .button_a {
        background-color: #008CBA;
        border: solid 1px #a1a1a1;
        color: white;
        padding: 9px 33px !important;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 10px 22px;
        cursor: pointer;
         width: 15%; 
        border-radius: 4px;
        float:right;
    }
}